import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { FC } from 'react'

import dynamicContentStyles from '@/pages/dynamic-content-styles.module.scss'

import { AuthorArticleProps } from './author-article.props'
import styles from './author-article.styles.module.scss'

export const AuthorArticle: FC<AuthorArticleProps> = ({ block, className }) => {
  return (
    <section className={clsx(styles.container, className)}>
      <h2 className={styles.title}>
        {block.link ? (
          <Link
            href={block.link.href}
            target={block.link.target}
          >
            {block.title}
          </Link>
        ) : (
          block.title
        )}
      </h2>
      <Image
        alt={block.author.data.attributes.alternativeText}
        className={styles.image}
        height={block.author.data.attributes.height}
        src={block.author.data.attributes.url}
        width={block.author.data.attributes.width}
      />
      <div className={styles.contentContainer}>
        {block.content.map((item) => (
          <div
            key={item.id}
            className={dynamicContentStyles.container}
            dangerouslySetInnerHTML={{ __html: item.content }}
          />
        ))}
      </div>
    </section>
  )
}
