import clsx from 'clsx'
import { Fragment, ReactElement } from 'react'

import { PageComponent } from '@/entities/collection/api/interfaces/get-page-response.interface'
import { AuthorArticle } from '@/entities/collection/ui/author-article'
import { PageTransitionLoader } from '@/features/page-transition-loader'
import { SeoHeader } from '@/features/seo-header'
import { PropsWithClassName } from '@/shared/interfaces'
import { Breadcrumbs } from '@/widgets/breadcrumbs'

import { AnyPageContext } from './_app.page'
import dynamicContentStyles from './dynamic-content-styles.module.scss'
import styles from './page.styles.module.scss'

interface AnyPageProps extends PropsWithClassName {
  page: AnyPageContext['page']
  global: AnyPageContext['global']
}

export function AnyPage({ className, page, global }: AnyPageProps) {
  return (
    <section className={clsx(styles.container, className)}>
      <Breadcrumbs className={styles.breadcrumbs} />

      {page.attributes.blocks.map((block) => (
        <Fragment key={block.id}>{blocksFabric(block)}</Fragment>
      ))}

      <SeoHeader
        global={global}
        page={page}
      />
      <PageTransitionLoader />
    </section>
  )
}

function blocksFabric(block: PageComponent['blocks'][number]): ReactElement | null {
  switch (block.__component) {
    case 'blocks.authors-article': {
      return (
        <AuthorArticle
          block={block}
          className={styles.block}
        />
      )
    }

    case 'shared.html':
      return (
        <section
          className={dynamicContentStyles.container}
          dangerouslySetInnerHTML={{ __html: block.content }}
        />
      )

    default:
      return null
  }
}
