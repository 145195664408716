import Head from 'next/head'
import Script from 'next/script'
import React, { FC, Fragment, useMemo } from 'react'

import { Page } from '@/entities/collection/api/interfaces/get-page-response.interface'
import { Global } from '@/entities/component/api/interfaces/get-global-response.interface'

interface SeoHeaderProps {
  page: Page
  global: Global
}

export const SeoHeader: FC<SeoHeaderProps> = ({ page, global }) => {
  const headMap = useMemo(() => {
    const pageHead = page.attributes.head
    const globalHead = global.head

    const keys: Record<Global['head'][number]['__component'], Set<string>> = {
      'head.head-link': new Set(),
      'head.meta': new Set(),
      'shared.script': new Set(),
    }

    return pageHead.concat(globalHead).filter((item) => {
      const currentKeySet = keys[item.__component]
      switch (item.__component) {
        case 'head.meta': {
          if (!currentKeySet.has(item.name)) {
            currentKeySet.add(item.name)

            return true
          }

          return false
        }
        case 'head.head-link': {
          if (!currentKeySet.has(item.href)) {
            currentKeySet.add(item.href)

            return true
          }

          return false
        }
        case 'shared.script': {
          if (!currentKeySet.has(item.src?.data?.attributes.url ?? item.code!)) {
            currentKeySet.add(item.src?.data?.attributes.url ?? item.code!)

            return true
          }

          return false
        }
      }
    })
  }, [global.head, page.attributes.head])

  const scripts = headMap.filter((item) => item.__component === 'shared.script')
  const restMeta = headMap.filter((item) => ['head.meta', 'head.head-link'].includes(item.__component))

  const title = useMemo(() => {
    if (!!page.attributes.seo?.title) {
      return <title>{page.attributes.seo.title}</title>
    }

    if (global.title) {
      return <title>{global.title}</title>
    }

    return null
  }, [global.title, page.attributes.seo?.title])

  return (
    <>
      <Head>
        {title}
        {restMeta.map(getComponentByType)}
        <link
          href="/images/android-chrome-192x192.png"
          rel="icon"
          sizes="192x192"
          type="image/png"
        />
        <link
          href="/images/apple-touch-icon.png"
          rel="apple-touch-icon"
          sizes="180x180"
        />
        <link
          color="#6531B4"
          href="/favicon.ico"
          rel="mask-icon"
        />
        <link
          href="/images/favicon_-48x48.png"
          rel="icon"
          sizes="48x48"
          type="image/png"
        />
        <link
          href="/images/favicon-32x32.png"
          rel="icon"
          sizes="32x32"
          type="image/png"
        />
        <link
          href="/images/favicon-16x16.png"
          rel="icon"
          sizes="16x16"
          type="image/png"
        />
        <link
          href="/favicon.ico"
          rel="icon"
          type="image/x-icon"
        />
        <link
          href="/manifest.json"
          rel="manifest"
        />
        <meta
          content="/browserconfig.xml"
          name="msapplication-config"
        />
      </Head>
      {scripts.map(getComponentByType)}
    </>
  )
}

function getComponentByType(component: Global['head'][number], index: number) {
  switch (component.__component) {
    case 'head.meta':
      return (
        <meta
          key={index}
          content={component.content}
          name={component.name}
        />
      )
    case 'head.head-link':
      return (
        <link
          key={index}
          crossOrigin={component.crossOrigin ?? undefined}
          href={component.href}
          hrefLang={component.hrefLang ?? undefined}
          media={component.media ?? undefined}
          referrerPolicy={component.referrerPolicy ?? undefined}
          rel={component.rel}
        />
      )
    case 'shared.script':
      return (
        <Script
          key={index}
          async={component.async ?? undefined}
          dangerouslySetInnerHTML={{ __html: component.code ?? '' }}
          defer={component.defer ?? undefined}
          id={component.id.toString()}
          src={component.src?.data?.attributes.url}
          strategy={component.strategy}
          type={component.type}
        />
      )
    default:
      return <Fragment key={index} />
  }
}
