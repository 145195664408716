import BreadcrumbLib from 'antd/lib/breadcrumb'
import { BreadcrumbItemType } from 'antd/lib/breadcrumb/Breadcrumb'
import clsx from 'clsx'
import Link from 'next/link'
import { FC, useMemo } from 'react'

import { usePageProps } from '@/pages/_app.page'
import { useParamsLocale } from '@/shared/hooks/use-params-locale.hook'
import { PropsWithClassName } from '@/shared/interfaces'

import styles from './breadcrumbs.styles.module.scss'

interface BreadcrumbsProps extends PropsWithClassName {}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ className }) => {
  const { page, navigation } = usePageProps()
  const paramsLocale = useParamsLocale()

  const defaultPage = useMemo(() => navigation.pages.data.find((page) => page.attributes.isDefault), [navigation])

  const items = useMemo<BreadcrumbItemType[]>(() => {
    if (!defaultPage) {
      return []
    }

    return [defaultPage, page].map<BreadcrumbLinkItemProps>((singlePage, index) => {
      const href = paramsLocale
        ? `/${paramsLocale}${defaultPage.attributes.href}`
        : singlePage.attributes.isDefault
          ? '/'
          : singlePage.attributes.href

      return {
        href,
        position: index + 1,
        key: singlePage.id,
        title: singlePage.attributes.title,
        locale: singlePage.attributes.locale,
        disabled: singlePage.id !== defaultPage.id,
      }
    })
  }, [defaultPage, paramsLocale, page])

  if (page.id === defaultPage?.id) {
    return null
  }

  return (
    <BreadcrumbLib
      className={clsx(styles.container, className)}
      itemRender={itemRender as any}
      items={items}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      itemType="https://schema.org/BreadcrumbList"
      itemScope
    />
  )
}

interface BreadcrumbLinkItemProps extends BreadcrumbItemType {
  disabled?: boolean
  locale: string
  position: number
  href: string
}
const itemRender: FC<BreadcrumbLinkItemProps> = ({ title, href, disabled, position, className }) => {
  const children = (
    <>
      <span itemProp="name">{title}</span>
      <meta
        content={position.toString()}
        itemProp="position"
      />
    </>
  )

  return (
    <div
      className={className}
      itemProp="itemListElement"
      itemType="https://schema.org/ListItem"
      itemScope
    >
      {disabled ? (
        children
      ) : (
        <Link
          href={href}
          itemProp="item"
        >
          {children}
        </Link>
      )}
    </div>
  )
}
