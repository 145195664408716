import { Spin } from 'antd'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { FC, useCallback, useEffect, useState } from 'react'

import { PropsWithClassName } from '@/shared/interfaces'

import styles from './page-transition-loader.styles.module.css'

export const PageTransitionLoader: FC<PropsWithClassName> = ({ className }) => {
  const [isPageLoading, setIsPageLoading] = useState(false)
  const { events } = useRouter()

  const onStartLoading = useCallback(() => setIsPageLoading(true), [])
  const onStopLoading = useCallback(() => setIsPageLoading(false), [])

  useEffect(() => {
    events.on('routeChangeStart', onStartLoading)
    events.on('routeChangeComplete', onStopLoading)

    return () => {
      events.off('routeChangeStart', onStartLoading)
      events.off('routeChangeComplete', onStopLoading)
    }
  }, [events, onStartLoading, onStopLoading])

  return (
    <Spin
      className={clsx(styles.container, className)}
      size="large"
      spinning={isPageLoading}
      fullscreen
    />
  )
}
