import { GetStaticProps } from 'next'

import { collectionsApi } from '@/entities/collection/api'
import { componentsApi } from '@/entities/component/api'
import { localesApi } from '@/entities/locale/api'
import { env } from '@/shared/env'
import { PropsWithClassName } from '@/shared/interfaces'

import { AnyPage } from './_any-page-content.component'
import { AnyPageContext, usePageProps } from './_app.page'

export default function RootPage({ className }: PropsWithClassName) {
  const { page, global } = usePageProps()

  return (
    <AnyPage
      className={className}
      global={global}
      page={page}
    />
  )
}

export const getStaticProps: GetStaticProps<AnyPageContext> = async () => {
  const { code: locale } = await localesApi.getDefaultLocale()
  const page = await collectionsApi.getPage({
    isDefault: true,
    locale,
  })

  if (!page) {
    return {
      redirect: {
        destination: '/500',
        permanent: false,
      },
    }
  }

  const [navigation, bannerLinks, global, locales] = await Promise.all([
    componentsApi.getNavigation({ locale }),
    componentsApi.getBannerLinks({ locale }),
    componentsApi.getGlobal({ locale }),
    localesApi.getLocales(),
  ])

  return {
    props: {
      page,
      locales,
      navigation,
      bannerLinks,
      global,
    },
    revalidate: env.get<number>('PAGE_REVALIDATE'),
  }
}
